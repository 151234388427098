@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
@layer components {
  .tg-lgray {
    @apply text-darkblue opacity-50;
  }
  .bc-lgray {
    @apply bg-darkblue opacity-50;
  }
  .variant-default-btn {
    @apply bg-primary disabled:bg-dark-primary  disabled:cursor-default hover:bg-dark-primary focus:bg-dark-primary outline-none border-0 rounded px-2 py-2 text-white cursor-pointer;
  }
}
.show__scroll::-webkit-scrollbar {
  display: none;
}
